import React, { useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import {
  ContainerProps,
  ControlProps,
  InputProps,
  MenuListProps,
  MenuProps,
  OptionProps,
  SingleValueProps,
  ValueContainerProps,
  IndicatorsContainerProps,
  DropdownIndicatorProps,
} from 'react-select';

import { Icon } from '../Icons';
import { Text } from '../Text';

import { OptionForm } from './DesktopSelectForm';

import css from './SelectForm.module.css';

/**
 *  When defining replacement components, it is important to do so outside the scope of rendering the Select.
 * Defining a replacement component directly in the components prop can cause issues
 *
 * @see {@link https://react-select.com/components#defining-components}
 */

type Option = OptionForm<any>;

type ControlUIProps = ControlProps<Option, false> & {
  hasUnemptyValue: boolean;
  id: string;
};
export const Control = React.memo<ControlUIProps>(
  ({ hasUnemptyValue, id, ...props }) => {
    return (
      <div
        ref={props.innerRef as any}
        {...props.innerProps}
        className={cx(props.className, css.control, {
          [css.controlWithValue]: hasUnemptyValue,
          [css.controlFocused]: props.isFocused,
          [css.controlDisabled]: props.isDisabled,
        })}
        id={id}
        onClick={() => {
          if (props.isDisabled) {
            return;
          }
          const element = document.getElementById(props.selectProps.inputId || '');
          if (element) {
            element.focus();
          }
        }}
      >
        {props.children}
      </div>
    );
  },
);

interface ValueContainerUIProps extends ValueContainerProps<Option, false> {
  label: string;
  error?: string;
  required?: boolean;
}
export const ValueContainer = React.memo<ValueContainerUIProps>(
  ({ label, error, required, ...props }) => (
    <div {...props.innerProps} className={css.valueContainer}>
      {label ? (
        <Text.Regular
          className={cx(css.valueLabel, {
            [css.valueLabelError]: Boolean(error),
            [css.valueLabelRequired]: required,
          })}
          htmlFor={props.selectProps.inputId}
          size={4}
          Tag="label"
        >
          {label}
        </Text.Regular>
      ) : null}
      {props.children}
    </div>
  ),
);

type OptionUIProps = OptionProps<Option, false>;
export const Option = React.memo<OptionUIProps>(props => (
  <div
    ref={props.innerRef}
    {...props.innerProps}
    className={cx(css.option, { [css.optionFocused]: props.isFocused })}
  >
    <Text.R3>{props.children}</Text.R3>
  </div>
));

export const SpecialOption = React.memo<OptionUIProps>(props => (
  <div
    ref={props.innerRef}
    {...props.innerProps}
    className={cx(css.option, css.specialOption, {
      [css.optionFocused]: props.isFocused,
    })}
  >
    <Text.R2>{props.children}</Text.R2>
    {props?.data?.renderImage ? props?.data?.renderImage() : null}
  </div>
));

type SingleValueUIProps = SingleValueProps<Option, false>;
export const SingleValue = React.memo<SingleValueUIProps>(props => {
  if (props.selectProps.isSearchable) {
    return null;
  }

  return (
    <Text.R2 {...props.innerProps} className={css.singleValue}>
      {props.children}
    </Text.R2>
  );
});

type ContainerUIProps = ContainerProps<Option, false>;
export const Container = React.memo<ContainerUIProps>(props => {
  return (
    <div {...props.innerProps} className={css.container}>
      {props.children}
    </div>
  );
});

type MenuUIProps = MenuProps<Option, false> & { controlId: string };
export const Menu = React.memo<MenuUIProps>(({ controlId, ...props }) => {
  const [width, setWidth] = useState<number>(0);

  // useEffect(() => console.log('open'), []);
  useEffect(() => {
    if (!props.selectProps.menuIsOpen) {
      return;
    }
    const parent = document.getElementById(controlId);
    if (!parent) {
      return;
    }

    setWidth(parent.clientWidth);
  }, [props.selectProps.menuIsOpen]);

  return (
    <div
      ref={props.innerRef}
      {...props.innerProps}
      className={css.menu}
      style={{ width }}
    >
      {props.children}
    </div>
  );
});
export const MobileMenu = React.memo<MenuUIProps>(({ controlId, ...props }) => {
  const [width, setWidth] = useState<number>(0);

  // useEffect(() => console.log('open'), []);
  useEffect(() => {
    if (!props.selectProps.menuIsOpen) {
      return;
    }
    const parent = document.getElementById(controlId);
    if (!parent) {
      return;
    }

    setWidth(parent.clientWidth);
  }, [props.selectProps.menuIsOpen]);

  return (
    <div
      // ref={props.innerRef}
      {...props.innerProps}
      // className={css.menu}
      style={{ width }}
    >
      {props.children}
    </div>
  );
});

type MenuListUIProps = MenuListProps<Option, false>;
export const MenuList = React.memo<MenuListUIProps>(props => {
  return (
    <div ref={props.innerRef} {...props.innerProps} className={css.menuList}>
      {props.children}
    </div>
  );
});
type IndicatorContainerUIProps = IndicatorsContainerProps<Option, false>;
export const IndicatorsContainer = React.memo<IndicatorContainerUIProps>(props => {
  return (
    <div {...props.innerProps} className={css.indicator}>
      {props.children}
    </div>
  );
});
type DropdownIndicatorUIProps = DropdownIndicatorProps<Option, false>;
export const DropdownIndicator = React.memo<DropdownIndicatorUIProps>(props => {
  return (
    <Icon.ArrowDown {...(props.innerProps as any)} className={css.indicatorIcon} />
  );
});

type InputUIProps = InputProps<Option, false>;
export const Input = React.memo<InputUIProps>(props => {
  const value = useMemo(() => {
    const values = props.getValue();
    if (values.length === 0) {
      return props.defaultValue as string;
    }

    return values[0].label;
  }, [props]);

  return (
    <input
      // Preserve these to save input-select behavior
      ref={props.innerRef}
      className={cx(css.input, props.className)}
      id={props.selectProps.inputId}
      placeholder={value}
      tabIndex={0}
      onBlur={props.onBlur}
      onChange={props.onChange}
      onFocus={props.onFocus}
    />
  );
});
