import React, { useId, useMemo } from 'react';

import { GroupBase, SelectComponentsConfig } from 'react-select';

import { Option as OptionType, Select, SelectProps } from '../Select';

import {
  Control,
  Option,
  SingleValue,
  ValueContainer,
  Container,
  Menu,
  MenuList,
  Input,
  SpecialOption,
  IndicatorsContainer,
  DropdownIndicator,
} from './Components';

export type SelectFormComponentProps<T> = SelectProps<T, OptionForm<T>> & {
  label: string;
  error?: string;
  searchable?: boolean;
  menuIsOpen?: boolean;
  menuPortalTarget?: HTMLElement | null;
  required?: boolean;
  isSpecial?: boolean;

  gridSize?: number;

  inputClassName?: string;
  selectClassName?: string;
};

export interface OptionForm<T> extends OptionType<T> {
  backgroundColor?: string;
  color?: string;
}

function SelectFormComponent<T>({
  options,
  value,
  defaultValue,
  disabled,
  error,
  isSpecial,
  onChange,
  renderSpecialIndicator,
  searchable = true,
  required = false,
  ...props
}: SelectFormComponentProps<T>): React.ReactElement {
  const id = useId();
  const controlId = useId();

  const labelText = useMemo(
    () => props.label + (error ? ` (${error})` : ''),
    [props.label, error],
  );

  const components = useMemo((): SelectComponentsConfig<
    OptionForm<T>,
    false,
    GroupBase<OptionForm<T>>
  > => {
    return {
      SelectContainer: data => (
        <Container {...data} className={props.selectClassName} />
      ),
      Control: data => (
        <Control
          {...data}
          className={props.selectClassName}
          hasUnemptyValue={
            value !== undefined && value !== null && value !== defaultValue
          }
          id={controlId}
        />
      ),
      ValueContainer: data => (
        <ValueContainer
          {...data}
          error={error}
          label={labelText}
          required={required}
        />
      ),
      SingleValue,
      Menu: data => <Menu {...data} controlId={controlId} />,
      MenuList,
      Option: data =>
        isSpecial ? <SpecialOption {...data} /> : <Option {...data} />,
      Input: data => <Input {...data} className={props.inputClassName} />,
      Placeholder: () => null,
      IndicatorSeparator: () => null,
      IndicatorsContainer: data =>
        renderSpecialIndicator ? (
          renderSpecialIndicator()
        ) : (
          <IndicatorsContainer {...data} />
        ),
      DropdownIndicator,
    };
  }, [value, searchable]);

  return (
    <Select
      components={components}
      defaultValue={defaultValue}
      disabled={disabled}
      gridSize={props.gridSize}
      inputId={id}
      menuIsOpen={props.menuIsOpen}
      menuPlacement={props.menuPlacement}
      menuPosition="fixed"
      menuShouldScrollIntoView={false}
      options={options}
      required={required}
      searchable={searchable}
      styles={{
        menuPortal: (base, data) => {
          const rect = data.rect as DOMRect;

          return {
            ...base,
            // bottom: ,
            ...(props.menuPlacement === 'top' ? { top: rect.bottom - 360 } : {}),
            zIndex: 99999999,
          };
        },
      }}
      theme={theme => ({
        ...theme,
        spacing: { ...theme.spacing, controlHeight: 18 },
      })}
      value={value}
      onChange={onChange}
    />
  );
}

export const DesktopSelectForm = React.memo(
  SelectFormComponent,
) as typeof SelectFormComponent;
