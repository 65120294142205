import React, { useMemo, useId } from 'react';

import ReactSelect, {
  GroupBase,
  MenuPlacement,
  MenuPosition,
  SelectComponentsConfig,
  StylesConfig,
  ThemeConfig,
} from 'react-select';

import { getGridSizeStyle } from 'components/Form/FormGrid';

/**
 * Preserve interface order:
 * options -> value -> etc
 * Ensures, that generic value will be evaluated from topmost provided key
 */
export interface SelectProps<T, O extends Option<T>> {
  options: O[];
  value: T;
  defaultValue?: T;

  disabled?: boolean;
  required?: boolean;
  menuPlacement?: MenuPlacement;

  gridSize?: number;
  onChange?: (newValue: T) => void;
  renderSpecialIndicator?: () => React.ReactElement | null;
}

export interface SpecificSelectProps<
  T,
  O extends Option<T>,
  IsMulti extends boolean,
> {
  searchable?: boolean;
  menuIsOpen?: boolean;
  theme?: ThemeConfig;
  styles?: StylesConfig<O, IsMulti>;
  components?: SelectComponentsConfig<O, IsMulti, GroupBase<O>>;
  delimiter?: string;
  menuPortalTarget?: HTMLElement | null;
  menuShouldScrollIntoView?: boolean;
  menuPosition?: MenuPosition;
  inputId?: string;
}

export interface Option<T> {
  value: T;
  label: string;
  renderImage?: () => React.ReactElement;
}

type SelectAllProps<T, O extends Option<T>> = SelectProps<T, O> &
  SpecificSelectProps<T, O, false> & { selectClassName?: string };

function SelectComponent<T, O extends Option<T>>({
  options,
  value,
  defaultValue,

  searchable = false,
  disabled = false,
  required = false,
  menuIsOpen,
  inputId,
  selectClassName,
  onChange = () => undefined,
  gridSize,

  menuPortalTarget = typeof document === 'undefined'
    ? null
    : document?.getElementById('modal-root'),
  styles,
  theme,
  components,
  delimiter,
  menuPlacement,
  menuShouldScrollIntoView,
  menuPosition,
}: SelectAllProps<T, O>): React.ReactElement {
  // generate instanceId from React 18
  const id = useId();

  // selectedOption returns an Option<T> | undefined from provided T
  const selectedOption = useMemo<Option<T> | undefined>(
    () => options.find(option => option.value === value),
    [value],
  );

  // defaultOption returns a default value Option<T> | undefined from provided T
  const defaultOption = useMemo<Option<T> | undefined>(
    () => options.find(option => option.value === defaultValue),
    [defaultValue],
  );

  // const handleChange = useCallback(
  //   (newValue: SingleValue<Option<T>>) => {
  //     if (!newValue) {
  //       // should not occur
  //       return alert('No value passed');
  //     }

  //     onChange(newValue.value);
  //   },
  //   [onChange],
  // );

  const renderNativeSelect = () => {
    return (
      <select
        autoComplete="off"
        disabled={disabled}
        required={value !== 0 && required}
        style={{
          zIndex: -1,
          opacity: 0,
          position: 'absolute',
          top: 0,
          height: '100%',
          width: '100%',
          pointerEvents: 'none',
        }}
        tabIndex={-1}
        value={(selectedOption?.value ?? defaultOption?.value ?? '') as string}
        onChange={() => {
          /* Must be defined, to avoid react error*/
        }}
      >
        {options.map((option, index) => (
          <option key={index} value={(option.value || '') as unknown as string}>
            {option.value === undefined ? 'y' : 'n'}
          </option>
        ))}
      </select>
    );
  };

  return (
    <div style={{ position: 'relative', ...getGridSizeStyle(gridSize) }}>
      <ReactSelect
        className={selectClassName}
        components={components as any}
        defaultValue={defaultOption}
        delimiter={delimiter}
        inputId={inputId}
        instanceId={id}
        isDisabled={disabled}
        isSearchable={searchable}
        menuIsOpen={menuIsOpen}
        menuPlacement={menuPlacement}
        menuPortalTarget={menuPortalTarget}
        menuPosition={menuPosition}
        menuShouldScrollIntoView={menuShouldScrollIntoView}
        options={options}
        styles={styles as any}
        theme={theme}
        value={selectedOption || defaultOption}
        onChange={newValue => onChange(newValue?.value as any)}
      />
      {renderNativeSelect()}
    </div>
  );
}

export const Select = React.memo(SelectComponent) as typeof SelectComponent;
