import React from 'react';

import { useApp } from 'components/ContextApp';

import { DesktopSelectForm, SelectFormComponentProps } from './DesktopSelectForm';
import { MobileSelectForm } from './MobileNativeSelectForm';
// import { MobileSelectForm } from './MobileSelectForm';

function SelectFormComponent<T>(
  props: SelectFormComponentProps<T>,
): React.ReactElement {
  const app = useApp();

  if (app.isMobile) {
    return <MobileSelectForm {...props} />;
  }

  return <DesktopSelectForm {...props} />;
}

export const SelectForm = React.memo(
  SelectFormComponent,
) as typeof SelectFormComponent;
