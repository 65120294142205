import React, { useId, useMemo } from 'react';

import cx from 'classnames';

import { getGridSizeStyle } from 'components/Form/FormGrid';
import { Icon } from 'components/Icons';

import { useText } from 'text';

import { Option as OptionType, SelectProps } from '../Select';

import css from './SelectForm.module.css';

type SelectFormComponentProps<T> = SelectProps<T, OptionForm<T>> & {
  label: string;
  error?: string;
  searchable?: boolean;
  menuIsOpen?: boolean;
  menuPortalTarget?: HTMLElement | null;
  required?: boolean;
  isSpecial?: boolean;

  gridSize?: number;

  inputClassName?: string;
  selectClassName?: string;
};

export interface OptionForm<T> extends OptionType<T> {
  backgroundColor?: string;
  color?: string;
}

function SelectFormComponent<T>({
  options,
  value,
  defaultValue,
  disabled,
  error,
  renderSpecialIndicator,
  onChange = () => undefined,
  required = false,
  selectClassName,
  ...props
}: SelectFormComponentProps<T>): React.ReactElement {
  const text = useText(state => state.controls.actions);
  const controlId = useId();

  const labelText = useMemo(
    () => props.label + (error ? ` (${error})` : ''),
    [props.label, error],
  );

  return (
    <div
      className={css.mobileNativeSelectWrapper}
      style={{ ...getGridSizeStyle(props.gridSize) }}
    >
      <label
        className={cx(css.valueLabel, css.mobileNativeSelectLabel, {
          [css.valueLabelError]: Boolean(error),
          [css.valueLabelRequired]: required,
        })}
        htmlFor={controlId}
      >
        {labelText}
      </label>
      <select
        className={cx(
          css.mobileNativeSelect,
          {
            [css.withoutValue]: !value && value !== 0,
          },
          selectClassName,
        )}
        defaultValue={defaultValue as any}
        disabled={disabled}
        id={controlId}
        required={required}
        onChange={event => {
          const value = event.target.value;
          onChange(value as any);
        }}
      >
        <option disabled selected={!value && value !== 0}>
          {text.selectAnOption}
        </option>
        {options.map((option, key) => {
          return (
            <option
              key={key}
              selected={option.value === value}
              value={option.value as any}
            >
              {option.label}
            </option>
          );
        })}
      </select>
      <div className={css.indicatorWrapper}>
        {renderSpecialIndicator ? (
          renderSpecialIndicator()
        ) : (
          <Icon.ArrowDown className={css.mobileNativeSelectArrow} />
        )}
      </div>
    </div>
  );
}

export const MobileSelectForm = React.memo(
  SelectFormComponent,
) as typeof SelectFormComponent;
